import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { apiurl } from 'src/app/global';

@Injectable({
	providedIn: 'root'
})
export class LoginService {
	logindata = new EventEmitter<any>();
	public access_token:string = '';

	constructor(
		private http:HttpClient
	) { }
	setUserToken(data:any){
		localStorage.setItem("usertoken",JSON.stringify(data));
	}
	setUserdata(data:any){
		localStorage.setItem("userdata",JSON.stringify(data));
	}
	setGroup(data:any)
	{
		localStorage.setItem("usergroup",JSON.stringify(data));
	}
	getUserdata(){
		let data:any = JSON.parse(localStorage.getItem('userdata')!);
		return data
	}
	getUserToken(){
		return JSON.parse(localStorage.getItem('usertoken')!);
	}

	onLogin(data:any){
		const headers = new HttpHeaders();
		headers.append('Content-Type','enctype/form-data');
		const fdata:FormData = new FormData();
		fdata.append('username',data['username'])
		fdata.append('password',data['password'])
		return this.http.post(apiurl+'login/',fdata,{headers:headers,observe:"response"})
	}
	addProfile(result: any){
		console.log(result);
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');   
		return this.http.post(apiurl + 'register/',result,{headers:headers});
	
		}
	addShop(result: any){
			console.log(result);
			const headers = new HttpHeaders();
			headers.append('Content-Type','application/json');   
			return this.http.post(apiurl + 'superadmin/shop/',result,{headers:headers});
		
			}
	getUserDetails(data:any){
		const header = new HttpHeaders();
		header.append('Content-Type','application/json');
		const fdata = {
			'username':data['username'],
			'password':data['password']
		}
		return this.http.post(apiurl+'user/details/',fdata,{headers:header})
	}
	getUser(){
 
		return this.http.get(apiurl + 'user/alluser/');
					 
		}
	sendmail(data:any)
		{
			
			const headers = new HttpHeaders();
			headers.append('Content-Type','application/json');   
			return this.http.post(apiurl + 'forgpass/',data,{headers:headers});
		}
	onforget(result:any){
 
			console.log(result);
			const headers = new HttpHeaders();
			headers.append('Content-Type','application/json');   
			return this.http.post(apiurl + 'checkuser/',result,{headers:headers});
						 
			}
		faceboklogin()
		{
			return this.http.get(apiurl + 'social-auth/login');	
		}
}
