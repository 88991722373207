
<div class="custom-dialog ">
	<h2 mat-dialog-title style="color:green">{{data.title}}</h2>
	<mat-dialog-content>
		<p>
			{{data.message}}
		</p>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		
		<button mat-raised-button color="info" [mat-dialog-close]="true">Ok</button>
	</mat-dialog-actions>
</div>