import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule} from '@angular/common/http';
import {MatDialogModule} from '@angular/material/dialog';
import { AppHttpInterceptor } from './AppHttpInterceptor';
import { AddItemService } from './agency/add-item/add-item.service';
import { AgencyProfileService } from './agency/agency-profile/agency-profile.service';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
@NgModule({
  declarations: [
    AppComponent,
    ForgetPasswordComponent,
    TermsConditionComponent,
    PrivacyPolicyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    MatDialogModule
   ],
  providers: [
],
  bootstrap: [AppComponent]
})
export class AppModule { }
