<!--************************************
				Home Slider Start
			*************************************-->
			<div id="tg-innerbanner" class="tg-innerbanner tg-haslayout">
				<figure data-vide-bg="poster: /assets/images/slider/img-01.jpg" data-vide-options="position: 50% 50%">
					<figcaption>
						<div class="container">
							<div class="row">
								<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
									<div class="tg-bannercontent">
										<h1>Kerala’s Largest Marketplace</h1>
									</div>
								</div>
							</div>
						</div>
					</figcaption>
				</figure>
			</div>
			<!--************************************
				Home Slider End
			*************************************--> 

<main id="tg-main" class="tg-main tg-haslayout"> 
    <!--************************************
        About Us Start
    *************************************-->
    <div class="container">
        <div class="row">
            <div id="tg-twocolumns" class="tg-twocolumns">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="container-fluid">      
                    
                        
                        <div class="tg-sortandview" style="margin-top: 45px;">
                            <div class="row" style="margin: 10px;">
                               <h4 class="mt-5" style="text-align: center;">Edit Password</h4>
                                <form  [formGroup]="pswdForm" (ngSubmit)="onSubmit()" >
                               
                                  <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 form-group-search"><span>New Password</span>
                                      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 form-group">
                                                          <input type="password" formControlName="password" class="form-control"  >
                                                          <div *ngIf="f.password.touched && f.password?.invalid" class="invalid-feedback text-sm">
                                                            <div *ngIf="f.password.errors?.required">Password is required.</div>
                                                        </div>
                                                      </div>
                                  </div>
                              
                                  <div class="form-group-search"><span>Confirm New Paasword</span>
                                      <div class="form-group">
                                                          <input type="password" formControlName="confirmpassword" class="form-control" >
                                                          <div *ngIf="f.confirmpassword.touched && f.confirmpassword.invalid" class="invalid-feedback text-sm">
                                                            <div *ngIf="f.confirmpassword.errors?.required">Password is required.</div>
                                                            <div *ngIf="f.confirmpassword.errors?.confirmedValidator">Password and Confirm Password must be match.</div>
                                                        </div>
                                                      </div>
                                  
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
                                  <div class="tg-btnbox-searchbtn">   <button class="tg-btn" type="submit"  >Save</button></div>
                                </div>
                                </form>
                            </div>
                            
                      
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        <!--************************************
            About Us End
        *************************************--> 
    </main>