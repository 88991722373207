import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';

const routes: Routes = [
{ path: '', loadChildren: () => import('./design/design.module').then(m => m.DesignModule) },
{ path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) }, 
{ path: 'agency', loadChildren: () => import('./agency/agency.module').then(m => m.AgencyModule) }, 
{ path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
{ path:'forget/:id', component:ForgetPasswordComponent},
{ path:'company/privacypolicy', component:PrivacyPolicyComponent},
{ path:'company/terms-conditions', component:TermsConditionComponent}


];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

