import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { apiurl } from 'src/app/global';


@Injectable({
  providedIn: 'root'
})
export class AgencyProfileService {
  
  constructor(private http: HttpClient) { }
  public userdataEmitter:EventEmitter<any> = new EventEmitter();
  editShop(data:any,cid:number){
		//console.log(data);
		const headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this.http.put(apiurl+"shopadmin/shopedit/"+cid+"/",data,{headers:headers})
	  }
    changepswd(data:any,cid:number){
      //console.log(data);
      const headers = new HttpHeaders();
      headers.append('Content-Type','application/json');
      return this.http.put(apiurl+"updatepass/"+cid+"/",data,{headers:headers})
      }
      forgetpswd(data:any,cid:number){
        //console.log(data);
        const headers = new HttpHeaders();
        headers.append('Content-Type','application/json');
        return this.http.put(apiurl+"passwordreset/"+cid+"/",data,{headers:headers})
        }
    getOneShop(cid:number){
      return this.http.get(apiurl+"superadmin/shop_view/"+cid+"/")
    }
    getlocation(){
      return this.http.get(apiurl + 'superadmin/towns/');             
    }
    delShop(id:number,uid:number)
    {
      return this.http.get(apiurl + 'shopadmin/shopdelete/' +id+"/"+uid+"/");
    }
    loggedIn()
    {
      return !!localStorage.getItem("usertoken")
//return localStorage.getItem("usergroup")
    }
    logout()
    {
      localStorage.removeItem("usertoken")
      localStorage.removeItem("userdata")
      return this.http.get(apiurl + 'logout/');
      
    }
}