import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import {FormGroup,FormControl,Validators,FormArray, FormBuilder,NgForm, ValidatorFn, AbstractControl} from '@angular/forms';
import { AgencyProfileService } from '../agency/agency-profile/agency-profile.service';
import { SuccessComponent } from '../agency/success/success.component';
import { MatDialog } from '@angular/material/dialog';
import { LoginService } from '../login/login.service';
import { FailedComponent } from '../agency/failed/failed.component';
import { ConfirmedValidator } from 'src/app/confirmed.validator';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent  {
  pswdForm!: FormGroup;
  submitted = false;
  public user:any
  public cid:any
  constructor(private formBuilder: FormBuilder,private aservice:AgencyProfileService,private loginservice:LoginService,private dialog: MatDialog,private router: Router,private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
			this.cid = params.get('id')
		   
    })
    this.pswdForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirmpassword: ['', Validators.required],
     
  },
{
  validator: ConfirmedValidator('password', 'confirmpassword')
} 

   );
  }
  get f() { return this.pswdForm.controls; }
  onSubmit() {
    this.submitted = true;
     if (this.pswdForm.invalid) {
           return;
       }
      let data={
        "emailid":this.pswdForm.value.confirmpassword
      }
       this.aservice.forgetpswd(data,this.cid).subscribe((response:any)=>{
         console.log(response)
         if(response!="failed")
         {

         const confirmDialog = this.dialog.open(SuccessComponent, {
         data: {
           title: 'Success',
           message: 'Password Updated Successfully!'
         }
         });
         confirmDialog.afterClosed().subscribe(result => {
         this.router.navigate(['home']);
       });
     }
     else{
       const confirmDialog = this.dialog.open(FailedComponent, {
         data: {
           title: 'Failed',
           message: 'Passsword Updated Failed!'
         }
         });
         confirmDialog.afterClosed().subscribe(result => {
         this.router.navigate(['home']);
       });
     }
        
          }); 

   }
}
